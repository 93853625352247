import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 262px;
  height: 417px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow5};
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;

  .card-text {
    transition: background-image  0.5s ease-in-out;
  }

  &:hover {
    transform: scale(1.1);

    .card-text {
      background-image: url("/assets/images/svg/reusables/card-blue-bg.svg");
      background-size: cover;
      background-repeat: no-repeat;
      color: ${({ theme }) => theme.white};
    }
  }

  @media (max-width: 768px) {
    width: 200px;
    height: 350px;
  }

  @media (max-width: 480px) {
    width: 250px;
    height: 350px;
  }
`;

const IconSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 768px) {
    img {
      width: 70%;
    }
  }

  @media (max-width: 480px) {
    img {
      width: 50%;
    }
  }
`;

const TextSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  flex: 1;
  border-radius: 0 0 10px 10px;
  width: 100%;
`;

const SmallWhiteCard = ({ icon, text, hasTextBackground }) => {
  return (
    <Container>
      <IconSection>{icon}</IconSection>
      <TextSection className="card-text" hasTextBackground={hasTextBackground}>
        {text}
      </TextSection>
    </Container>
  );
};

export default SmallWhiteCard;
