"use client";

import React from "react";
import { TermsAndConditionsContainer, Title } from "./styles";
import { RightContentContainer } from "../home/styles";
import { ExtrasContainer } from "../privacy/style";
import ImageTextSection from "../../components/Containers/ImageTextSection";

const TermsAndConditions = () => {
  return (
    <TermsAndConditionsContainer>
      <ImageTextSection
        leftContent={
          <div style={{ lineHeight: "50px" }}>
            <Title>Terms and Conditions</Title>
            <h2>Introduction</h2>
            <p>
              Welcome to ZAMDAHEALTH ("we," "our," or "us"). These Terms and
              Conditions ("Terms") govern your access to and use of our
              services, including our mobile application, point-of-sale (POS)
              system, website, and any other services provided by us
              (collectively, the "Services").
            </p>
            <p>
              By accessing or using our Services, you agree to comply with and
              be bound by these Terms. If you do not agree with these Terms, you
              must not access or use the Services.
            </p>

            <h2>1. Eligibility</h2>
            <p>
              You must be at least 16 years old to use our Services. By
              accessing or using the Services, you represent and warrant that
              you meet the eligibility requirements.
            </p>

            <h2>2. Account Registration</h2>
            <p>
              To access certain features of our Services, you may be required to
              create an account. When you create an account, you agree to:
            </p>
            <ul>
              <li>Provide accurate, current, and complete information.</li>
              <li>Maintain and promptly update your account information.</li>
              <li>Keep your login credentials confidential.</li>
              <li>
                Be responsible for all activities that occur under your account.
              </li>
            </ul>
            <p>
              We reserve the right to suspend or terminate your account if we
              suspect any unauthorized use or if you violate these Terms.
            </p>
          </div>
        }
        rightContent={
          <RightContentContainer>
            <img
              src="/assets/images/svg/reusables/green-doc.svg"
              className="banner"
              width={0}
              height={0}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
              alt=""
            />
          </RightContentContainer>
        }
      />
      <ExtrasContainer>
        <h2>3. Use of Services</h2>
        <p>
          You agree to use our Services in accordance with these Terms and all
          applicable laws and regulations.
        </p>
        <p>You agree not to:</p>
        <ul>
          <li>Use the Services for any illegal or unauthorized purpose.</li>
          <li>
            Access, tamper with, or use non-public areas of the Services or our
            systems.
          </li>
          <li>
            Interfere with or disrupt the Services, servers, or networks
            connected to the Services.
          </li>
          <li>
            Impersonate any person or entity or falsely state or misrepresent
            your affiliation with a person or entity.
          </li>
          <li>
            Use any automated system, including "bots," to access the Services
            in a manner that sends more requests than a human could reasonably
            produce.
          </li>
        </ul>

        <h2>4. Payments and Subscriptions</h2>
        <h3>4.1. Pricing and Payments</h3>
        <p>
          If you make purchases through our Services, you agree to pay all fees
          and charges in accordance with the pricing and payment terms presented
          to you. All payments are due at the time of purchase unless otherwise
          specified.
        </p>

        <h3>4.2. Subscriptions</h3>
        <p>
          Some of our Services may be available on a subscription basis. If you
          subscribe to a service, you agree to pay the applicable subscription
          fees. Subscriptions will automatically renew unless you cancel them
          before the renewal date.
        </p>

        <h3>4.3. Refunds</h3>
        <p>
          Refunds will be provided only as specified in our refund policy. All
          sales are final unless otherwise stated.
        </p>

        <h2>5. Health Information and Advice</h2>
        <h3>5.1. No Medical Advice</h3>
        <p>
          The health-related information provided through our Services is for
          informational purposes only and is not intended as medical advice.
          Always seek the advice of a qualified healthcare provider before
          making any medical decisions or starting any new treatment.
        </p>

        <h3>5.2. Accuracy of Information</h3>
        <p>
          We strive to provide accurate and up-to-date information. However, we
          do not guarantee the accuracy, completeness, or timeliness of any
          information available through the Services.
        </p>

        <h2>6. Intellectual Property</h2>
        <h3>6.1. Ownership</h3>
        <p>
          All content, features, and functionality available through the
          Services, including but not limited to text, graphics, logos, icons,
          images, audio clips, and software, are the exclusive property of
          ZAMDAHEALTH and are protected by applicable intellectual property
          laws.
        </p>

        <h3>6.2. Limited License</h3>
        <p>
          We grant you a limited, non-exclusive, non-transferable, revocable
          license to access and use the Services for your personal,
          non-commercial use. This license is subject to these Terms and does
          not include any rights to:
        </p>
        <ul>
          <li>
            Reproduce, distribute, or publicly display any content from the
            Services without our prior written consent.
          </li>
          <li>Modify or create derivative works of the Services.</li>
          <li>
            Reverse engineer or attempt to extract the source code of the
            Services.
          </li>
        </ul>

        <h3>6.3. Trademarks</h3>
        <p>
          All trademarks, service marks, and trade names associated with the
          Services are the property of ZAMDAHEALTH. You may not use any of these
          trademarks without our prior written permission.
        </p>

        <h2>7. Third-Party Services and Links</h2>
        <p>
          Our Services may contain links to third-party websites, services, or
          content that are not owned or controlled by us. We are not responsible
          for the content, privacy policies, or practices of any third-party
          websites or services. Your use of third-party services is at your own
          risk, and you should review the terms and privacy policies of those
          services before using them.
        </p>

        <h2>8. User Content</h2>
        <h3>8.1. Your Responsibilities</h3>
        <p>
          You may be able to post, submit, or share content through our Services
          ("User Content"). You are solely responsible for your User Content and
          the consequences of sharing it. You agree not to post or share any
          User Content that:
        </p>
        <ul>
          <li>Violates any applicable law or regulation.</li>
          <li>Infringes on the intellectual property rights of others.</li>
          <li>Is defamatory, obscene, or harmful to minors.</li>
          <li>Contains viruses or other harmful code.</li>
        </ul>

        <h3>8.2. License to User Content</h3>
        <p>
          By submitting User Content, you grant us a worldwide, non-exclusive,
          royalty-free, transferable, and sublicensable license to use,
          reproduce, modify, distribute, and display your User Content in
          connection with the operation and promotion of the Services.
        </p>

        <h3>8.3. Monitoring and Removal</h3>
        <p>
          We reserve the right, but are not obligated, to monitor, remove, or
          disable access to any User Content that violates these Terms or is
          otherwise objectionable.
        </p>

        <h2>9. Data Privacy</h2>
        <p>
          Your privacy is important to us. Please review our Privacy Policy,
          which explains how we collect, use, and protect your personal
          information. By using the Services, you agree to the terms of our
          Privacy Policy.
        </p>

        <h2>10. Termination</h2>
        <h3>10.1. Termination by You</h3>
        <p>
          You may terminate your account at any time by following the
          instructions provided in the Services or by contacting us at{" "}
          <a href="mailto:admin@zamdahealth.com">admin@zamdahealth.com</a>.
          Termination of your account will not relieve you of any obligations to
          pay any outstanding fees.
        </p>

        <h3>10.2. Termination by Us</h3>
        <p>
          We may terminate or suspend your access to the Services, in whole or
          in part, without notice and without liability, if you violate these
          Terms or if we believe that your actions may cause harm to us, other
          users, or third parties.
        </p>

        <h3>10.3. Effect of Termination</h3>
        <p>
          Upon termination, your right to use the Services will immediately
          cease. Any provisions of these Terms that by their nature should
          survive termination will survive, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>

        <h2>11. Disclaimers and Limitation of Liability</h2>
        <h3>11.1. Disclaimer of Warranties</h3>
        <p>
          The Services are provided "as is" and "as available" without any
          warranties of any kind, whether express or implied. We disclaim all
          warranties, including but not limited to, implied warranties of
          merchantability, fitness for a particular purpose, title, and
          non-infringement.
        </p>

        <h3>11.2. Limitation of Liability</h3>
        <p>
          To the fullest extent permitted by law, ZAMDAHEALTH and its
          affiliates, officers, directors, employees, and agents shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, resulting from:
        </p>
        <ul>
          <li>
            Your access to or use of or inability to access or use the Services.
          </li>
          <li>Any conduct or content of any third party on the Services.</li>
          <li>Any content obtained from the Services.</li>
          <li>
            Unauthorized access, use, or alteration of your transmissions or
            content.
          </li>
        </ul>
        <p>
          In no event shall our liability exceed the amount you paid us, if any,
          for accessing or using the Services.
        </p>

        <h2>12. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless ZAMDAHEALTH and its
          affiliates, officers, directors, employees, and agents from and
          against any and all claims, liabilities, damages, losses, and
          expenses, including reasonable attorneys' fees and costs, arising out
          of or in any way connected with:
        </p>
        <ul>
          <li>Your access to or use of the Services.</li>
          <li>Your violation of these Terms.</li>
          <li>
            Your violation of any third-party rights, including intellectual
            property rights.
          </li>
        </ul>

        <h2>13. Governing Law and Dispute Resolution</h2>
        <h3>13.1. Governing Law</h3>
        <p>
          These Terms and your use of the Services shall be governed by and
          construed in accordance with the laws applicable in your jurisdiction,
          without regard to its conflict of laws principles.
        </p>

        <h3>13.2. Dispute Resolution</h3>
        <p>
          Any disputes arising out of or related to these Terms or the Services
          shall be resolved through binding arbitration in your jurisdiction, in
          accordance with the rules of the relevant arbitration body. You agree
          to waive any right to a jury trial or to participate in a class
          action.
        </p>

        <h2>14. Changes to These Terms</h2>
        <p>
          We may update these Terms from time to time in our sole discretion. We
          will notify you of any material changes by posting the new Terms on
          our website or through other means. Your continued use of the Services
          after the changes become effective constitutes your acceptance of the
          revised Terms.
        </p>

        <h2>15. Miscellaneous</h2>
        <h3>15.1. Entire Agreement</h3>
        <p>
          These Terms, together with our Privacy Policy and any other agreements
          or policies referenced herein, constitute the entire agreement between
          you and ZAMDAHEALTH regarding your use of the Services and supersede
          any prior agreements or understandings.
        </p>

        <h3>15.2. Severability</h3>
        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable, the remaining provisions shall remain in full force and
          effect.
        </p>

        <h3>15.3. Waiver</h3>
        <p>
          No waiver of any term or condition shall be deemed a further or
          continuing waiver of such term or condition or any other term or
          condition.
        </p>

        <h3>15.4. Assignment</h3>
        <p>
          You may not assign or transfer these Terms, in whole or in part,
          without our prior written consent. We may assign or transfer these
          Terms, in whole or in part, at any time without notice or consent.
        </p>

        <h3>15.5. Force Majeure</h3>
        <p>
          We shall not be liable for any failure or delay in the performance of
          our obligations under these Terms due to causes beyond our reasonable
          control, including but not limited to acts of God, natural disasters,
          war, terrorism, labor disputes, governmental actions, or failures of
          third-party services.
        </p>

        <h3>15.6. Notices</h3>
        <p>
          Any notices or other communications required or permitted under these
          Terms, including those regarding modifications to these Terms, will be
          in writing and may be given by email (to the address provided in your
          account), by posting on our website, or through the Services.
        </p>

        <h3>15.7. Headings</h3>
        <p>
          The section headings in these Terms are for convenience only and have
          no legal or contractual effect.
        </p>

        <h2>16. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>
          <strong>ZAMDAHEALTH</strong>
        </p>
        <p>Address:</p>
        <p>
          Email:{" "}
          <a href="mailto:admin@zamdahealth.com">admin@zamdahealth.com</a>
        </p>
        <p>Phone: +2347065477408</p>
      </ExtrasContainer>
    </TermsAndConditionsContainer>
  );
};

export default TermsAndConditions;
