"use client";
import React from "react";
import {
  AbsoluteDots,
  FeatureContainer,
  LeftContentContainer,
  MobileContainer,
  RightContentContainer,
} from "./style";
import ImageTextSection from "../../../components/Containers/ImageTextSection";
import CardListSection from "../../../components/Containers/CardListSection";
import { AppsLogoContainer } from "../../home/styles";
import { mobileAppCardList, mobileAppFeatures } from "../../home/utils";

const Mobile = () => {
  return (
    <MobileContainer>
      <ImageTextSection
        leftContent={
          <LeftContentContainer>
            <p className="title-700">Mobile Application</p>
            <p className="regular-text">
              Our Mobile App is an innovative health tech Application which its
              core purpose is to promote access to quality medicine by making
              the process of ordering prescription and over-the-counter
              medications from a network of pharmacies easier.
            </p>
            <AppsLogoContainer>
              <img
                src="/assets/images/svg/reusables/apple-store.svg"
                style={{
                  cursor: "pointer",
                }}
                alt="apple-store-logo"
              />
              <img
                src="/assets/images/svg/reusables/google-play-store.svg"
                style={{
                  cursor: "pointer",
                }}
                alt="google-store-logo"
              />
            </AppsLogoContainer>
          </LeftContentContainer>
        }
        rightContent={
          <img
            alt=""
            src="/assets/images/svg/modile-app-images/mobile-phone.svg"
            width={0}
            height={0}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
          />
        }
        customStyles={{ alignItems: "center" }}
      />
      <CardListSection
        sectionTitle={"Main Mobile Application Features"}
        cards={mobileAppCardList}
      />
      <CardListSection
        // sectionTitle={"Main Mobile Application Features"}
        cards={mobileAppFeatures}
      />
      <ImageTextSection
        leftContent={
          <>
            <LeftContentContainer>
              <AbsoluteDots src="/assets/images/svg/reusables/cyan-dots.svg" />
            </LeftContentContainer>
            <ima
              alt=""
              src="/assets/images/svg/modile-app-images/doctors-on-heart.svg"
              className="banner"
              width={0}
              height={0}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
            />
          </>
        }
        rightContent={
          <RightContentContainer>
            <p className="title-600 title">Other Mobile App Features</p>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/green-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span className="selected">Allergies detection</span>
            </FeatureContainer>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/purple-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span>AI Chat Box</span>
            </FeatureContainer>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/brown-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span>Customer Support</span>
            </FeatureContainer>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/cyan-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span>Sign Up Bonuses</span>
            </FeatureContainer>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/green-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span>Order Traking</span>
            </FeatureContainer>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/pink-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span>Marketing Footer for Companies</span>
            </FeatureContainer>
            <FeatureContainer>
              <img
                alt=""
                src="/assets/images/svg/modile-app-images/purple-check.svg"
                style={{ width: "20px", height: "20px" }}
                width={0}
                height={0}
              />
              <span>
                User Friendly Interface for Browsing and Comparing Medication
                Prices
              </span>
            </FeatureContainer>
          </RightContentContainer>
        }
        customBackgroundUrl={
          "/assets/images/svg/reusables/grey-top-curvy-bg.svg"
        }
        customStyles={{ alignItems: "center" }}
      />
    </MobileContainer>
  );
};

export default Mobile;
