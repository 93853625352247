import styled from "styled-components";
import { device } from "../../theme";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media ${device.tablet} {
    margin-top: 40px;
  }

  @media ${device.mobileL} {
    margin-top: 30px;
  }

  @media ${device.mobileM} {
    margin-top: 20px;
  }

  @media ${device.mobileS} {
    margin-top: 10px;
  }
`;

export const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;

  @media ${device.tablet} {
    gap: 15px;
     text-align: center;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 5px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    gap: 15px;
     text-align: center;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 5px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

export const AwaitingListModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000000;
  backdrop-filter: blur(10px);
`;

export const AwaitingListContainer = styled.div`
width: 50%;
background: #fff;
padding: 20px;
border-radius: 15px;

  @media ${device.laptopL} {
    width: 60%;
  }

  @media ${device.laptop} {
    width: 70%;

  }

  @media ${device.tablet} {
    flex-direction: column;
    width: 80%;

  }

  @media ${device.mobileL} {
    width: 90%;

  }
`;