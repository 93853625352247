export const textSizes = {
  fontSize80: "83px",
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};

// src/theme.js
export const lightTheme = {
  textGrey: "#343030",
  white: "#ffffff",
  black: "#000",
  black2: "#121212",
  black3: "#051114",
  black4: "#1A1A1A",
  grey: "#707070",
  grey2: "#5b5b5b",
  grey3: "#C3CAD9",
  green: "#04D3BC",
  blue: "#211F7E",
  purple: "#4D4B9D",
  
  brown: "#A26F3D",
  darkPurple: "#0D0D35",
  white2: "#FBF8F8",
  red: "#E01212",
  translucentBlack: "#0000000D",
  translucentCyan: "#04D3BC33",
  yellow: "#F2CD08",
  translucentYellow: "#F2CD0833",
  translucentWhite: "#F6F6FF",
  boxShadow1: "10px 0px 20px 2px #1414140D",
  boxShadow2: "0px 6px 30px 5px #12121240",
  boxShadow3: "0px 8px 10px 0px #20202040",
  boxShadow4: "4px 4px 4px 0px #0000001a",
  boxShadow5: "0px 6px 50px 5px #060e1a0d",
};

export const darkTheme = {};
