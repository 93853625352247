"use client";

import { useRef, useState } from "react";
import React from "react";
import emailjs from "@emailjs/browser";
import { AwaitingListContainer, AwaitingListModal } from "./style";
import { FormSection } from "../contact-us/style";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";
import TextInput from "../../components/Form/Inputs/TextInput";
const RequestDemo = ({ closeModal }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    setLoading(true);
    emailjs
      .sendForm("service_dakbi0p", "template_3bvjfwh", form.current, {
        publicKey: "mygPawbiDLK6-Jnfb",
      })
      .then(
        () => {
          alert("Message sent!");
          setLoading(false);
          closeModal();
        },
        (error) => {
          alert("FAILED...", error.text);
          setLoading(false);
        }
      );
  };

  return (
    <AwaitingListModal>
      <AwaitingListContainer>
        <FormSection ref={form}>
          <div className="section-title">Request Demo</div>
          <TextInput
            label="Company Name"
            placeholder={""}
            required={true}
            name="company_name"
            type={"text"}
          />
          <TextInput
            label="Contact email"
            placeholder={"example@gmail.com"}
            required={true}
            name="email"
            type={"email"}
          />
          <TextInput
            label="Phone Number"
            placeholder={"+234 704 437 7963"}
            required={true}
            type={"tel"}
            name="phone_number"
          />
          <TextInput
            label="Location"
            placeholder={"Enugu, Nigeria"}
            required={true}
            name="location"
          />
          <div className="flex-row" style={{ flexDirection: "row" }}>
            <RoundedFilledButton
              btnText={"Join"}
              borderRadius="10px"
              type={"submit"}
              loading={loading}
              handleClick={sendEmail}
            />
            <RoundedFilledButton
              btnText={"Close"}
              borderRadius="10px"
              handleClick={closeModal}
            />
          </div>
        </FormSection>
      </AwaitingListContainer>
    </AwaitingListModal>
  );
};

export default RequestDemo;
