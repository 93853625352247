"use client";
import React from "react";
import {
  AppsLogoContainer,
  HomeContainer,
  LeftContentContainer,
} from "./styles";
import { DesktopAppCardList, mobileAppCardList } from "./utils";
import ContactUs from "../contact-us/page";
import AnyQuestion from "./AnyQuestion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import ImageTextSection from "../../components/Containers/ImageTextSection";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";
import CardListSection from "../../components/Containers/CardListSection";
import { useNavigate } from "react-router-dom";

const SliderContainer = styled.div`
  width: 100%;
  .slick-slide {
    display: flex;
    justify-content: center;
  }
`;

// const SampleNextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         background: "grey",
//         borderRadius: "50%",
//       }}
//       onClick={onClick}
//     >
//       {">"}
//     </div>
//   );
// };

// const SamplePrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: "block",
//         background: "grey",
//         borderRadius: "50%",
//       }}
//       onClick={onClick}
//     >
//       {"<"}
//     </div>
//   );
// };

const Home = () => {
  const router = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 3000,
    // arrows: true,
  };

  return (
    <HomeContainer>
      <SliderContainer>
        <Slider {...settings}>
          <div>
            <ImageTextSection
              leftContent={
                <>
                  <LeftContentContainer>
                    <p className="title-700">Zamda Health</p>
                    <p className="regular-text">
                      It is a technology-driven healthcare management company
                      specializing in connecting pharmacies, customers, and
                      healthcare professionals through a comprehensive online
                      platform.
                    </p>
                  </LeftContentContainer>
                  <RoundedFilledButton
                    btnText={"Learn More"}
                    borderRadius={"50px"}
                    handleClick={() => router("/about")}
                  />
                </>
              }
              rightContent={
                <>
                  <img
                    alt=""
                    src="/assets/images/svg/desktop-pos/doctors-stading.svg"
                    style={{ height: "100%", width: "100%" }}
                  />
                  <AppsLogoContainer>
                    <img
                      src="/assets/images/svg/reusables/apple-store.svg"
                      style={{
                        cursor: "pointer",
                      }}
                      alt="apple-store-logo"
                    />
                    <img
                      src="/assets/images/svg/reusables/google-play-store.svg"
                      style={{
                        cursor: "pointer",
                      }}
                      alt="google-store-logo"
                    />
                  </AppsLogoContainer>
                </>
              }
            />
          </div>
          <div>
            <ImageTextSection
              leftContent={
                <LeftContentContainer>
                  <p className="title-700">
                    Download Zamdahealth Mobile Application
                  </p>
                  <AppsLogoContainer>
                    <img
                      src="/assets/images/svg/reusables/apple-store.svg"
                      style={{
                        cursor: "pointer",
                      }}
                      alt="apple-store-logo"
                    />
                    <img
                      src="/assets/images/svg/reusables/google-play-store.svg"
                      style={{
                        cursor: "pointer",
                      }}
                      alt="google-store-logo"
                    />
                  </AppsLogoContainer>
                </LeftContentContainer>
              }
              rightContent={
                <img
                  alt=""
                  src="/assets/images/svg/modile-app-images/mobile-phone.svg"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                />
              }
              customStyles={{ alignItems: "center" }}
            />
          </div>
          <div>
            <ImageTextSection
              leftContent={
                <>
                  <LeftContentContainer>
                    <p className="title-700">
                      Request for Zamdahealth Point of Sale Software Demo for
                      your Store.
                    </p>
                  </LeftContentContainer>
                  <RoundedFilledButton
                    btnText={"Request Demo"}
                    borderRadius={"50px"}
                  />
                </>
              }
              rightContent={
                <img
                  alt=""
                  src="/assets/images/svg/about-us/tablet-phone.svg"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                />
              }
            />
          </div>
        </Slider>
      </SliderContainer>
      <ImageTextSection
        leftContent={
          <>
            <LeftContentContainer>
              <p className="sub-title-700">Zamda Health</p>
              <p className="regular-text">
                It is a technology-driven healthcare management company
                specializing in connecting pharmacies, customers, and healthcare
                professionals through a comprehensive online platform.
              </p>
            </LeftContentContainer>
            <LeftContentContainer>
              <p className="sub-title-700">Zamda Health Mobile App</p>
              <p className="regular-text">
                ZamdaHealth Mobile App is an innovative health tech Application
                which its core purpose is to promote access to quality medicine
                by making the process of ordering prescription and
                over-the-counter medications from a network of pharmacies
                easier. The company leverages artificial intelligence to
                streamline the entire healthcare process
              </p>
            </LeftContentContainer>
            <LeftContentContainer>
              <p className="sub-title-700">Zamda Health Point of Sale</p>
              <p className="regular-text">
                ZamdaHealth's advanced Point of Sale (POS) system for
                pharmacies, a comprehensive and versatile solution designed to
                streamline sales and inventory management. This powerful tool
                offers efficient transaction processing, automatic stock
                updates, and comprehensive sales analytics. It includes robust
                inventory management to keep track of stock levels and orders
                efficiently, and user management capabilities to handle customer
                profiles and preferences seamlessly.
              </p>
            </LeftContentContainer>
          </>
        }
        rightContent={
          <img
            alt=""
            src="/assets/images/svg/about-us/dashboard.svg"
            width={0}
            height={0}
            style={{ width: "90%", height: "100%" }}
          />
        }
      />

      <CardListSection
        sectionTitle={"Main Mobile Application Features"}
        cards={mobileAppCardList}
        showViewMore={true}
        viewMoreLink="/features/mobile"
      />
      <CardListSection
        sectionTitle={"Main Desktop Point of Sales Features"}
        cards={DesktopAppCardList}
        showViewMore={true}
        viewMoreLink="/features/desktop"
      />

      <ContactUs />

      <AnyQuestion />
    </HomeContainer>
  );
};

export default Home;
