import styled from "styled-components";
import { device } from "../../theme";

export const Container = styled.div`
  display: flex;
  padding: 100px;

  @media ${device.laptopL} {
    padding: 80px;
  }

  @media ${device.laptop} {
    padding: 60px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding: 40px;
  }

  @media ${device.mobileL} {
    padding: 20px;
  }

  @media ${device.mobileM} {
    padding: 10px;
  }

  @media ${device.mobileS} {
    padding: 5px;
  }
`;

export const FormSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 0.6;

  @media ${device.tablet} {
    flex: 1;
    gap: 20px;
    align-items: center;
  }

  @media ${device.mobileL} {
    gap: 15px;
  }

  @media ${device.mobileM} {
    gap: 10px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

export const ImageSection = styled.div`
  flex: 0.4;
  display: flex;
  align-items: center;

  img {
    width: 100%;
  }

  @media ${device.tablet} {
    flex: 1;
    margin-top: 20px;
  }
`;