import React from "react";
import styled from "styled-components";
import { device } from "../../theme";

const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: 100px;
  background-image: ${(props) =>
    props.customBackgroundUrl ? `url(${props.customBackgroundUrl})` : ""};
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  @media ${device.laptopL} {
    padding: 80px;
  }

  @media ${device.laptop} {
    padding: 60px;
  }

  @media ${device.tablet} {
    padding: 40px;
    flex-direction: column-reverse;
    align-items: center;
  }

  @media ${device.mobileL} {
    padding: 30px;
  }

  @media ${device.mobileM} {
    padding: 20px;
  }

  @media ${device.mobileS} {
    padding: 10px;
  }
`;

const LeftContent = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-self: center;

  @media ${device.tablet} {
    flex: 1;
    gap: 30px;
    align-items: center;
  }

  @media ${device.mobileL} {
    gap: 20px;
  }

  @media ${device.mobileM} {
    gap: 15px;
  }

  @media ${device.mobileS} {
    gap: 10px;
  }
`;

const RightContent = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media ${device.tablet} {
    flex: 1;
    gap: 15px;
    align-items: center;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 8px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

const ImageTextSection = ({
  leftContent,
  rightContent,
  customBackgroundUrl,
  customStyles,
}) => {
  return (
    <Container customBackgroundUrl={customBackgroundUrl} style={customStyles}>
      <LeftContent>{leftContent}</LeftContent>
      <RightContent>{rightContent}</RightContent>
    </Container>
  );
};

export default ImageTextSection;
