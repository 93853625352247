import styled from "styled-components";
import { device } from "../../theme";

export const TermsAndConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media ${device.tablet} {
    margin-top: 40px;
  }

  @media ${device.mobileL} {
    margin-top: 30px;
  }

  @media ${device.mobileM} {
    margin-top: 20px;
  }

  @media ${device.mobileS} {
    margin-top: 10px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 23.48px;
  color: ${({ theme }) => theme.blue};
  text-transform: uppercase;
  text-decoration: underline;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 21.13px;
  }

  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 18.78px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
    line-height: 16.44px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
    line-height: 14.1px;
  }
`;
