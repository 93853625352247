import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  position: relative;
  bottom: 0;
  background-color: ${({ theme }) => theme.background};

  @media (max-width: 1200px) {
    padding: 40px;
  }

  @media (max-width: 992px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const FooterTopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: #c3cad90d;
  padding-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.grey3};
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 100px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap:50px;
  }
`;

const FooterTopLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  max-width: 300px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const FooterLogo = styled.img`
  width: 205px;
  height: 45px;

  @media (max-width: 1200px) {
    width: 150px;
    height: auto;
  }
`;

const FooterTopLeftText = styled.p`
display: flex;
flex-direction: column;
gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 0.05em;
  text-align: left;
  color: ${({ theme }) => theme.grey2};

  @media (max-width: 768px) {
    font-size: 12px;
    align-items: center;
  }
`;

const FooterTopNavs = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;
  flex: 2;

  @media (max-width: 1200px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    gap: 10px;
    width: 100%;
  }
`;

const FooterTopNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FooterTopNavHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.black2};

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FooterTopNavItem = styled(Link)`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.grey2};
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const FooterTopItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: ${({ theme }) => theme.grey2};
  text-decoration: none;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const SubscribeSectionContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 445px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.grey3};
  position: relative;

  input {
    width: 100%;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    padding: 15px;
  }

  button {
    width: 157px;
    height: 64px;
    border-radius: 0 8px 8px 0;
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
  }

  @media (max-width: 768px) {
    /* flex-direction: column; */
    width: 100%;

    input {
      width: 100%;
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.blue};
  width: 100%;
  padding: 30px 0;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
    gap: 15px;
  }
`;

const FooterBottomRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;

  @media (max-width: 768px) {
    gap: 15px;
    /* flex-direction: column; */
    align-items: center;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterTopContainer>
        <FooterTopLeftContainer>
          <FooterLogo src="/assets/images/zamda-logo.svg" />
          <FooterTopLeftText>
            Zamda isn&apos;t your average pharmacy POS system. We allow users,
            both buyers and sellers to enjoy 100% satisfaction.
          </FooterTopLeftText>
        </FooterTopLeftContainer>
        <FooterTopNavs>
          <FooterTopNavContainer>
            <FooterTopNavHeader>Navigation</FooterTopNavHeader>
            <FooterTopNavItem to="/">Home</FooterTopNavItem>
            <FooterTopNavItem to="/about">About</FooterTopNavItem>
            <FooterTopNavItem to="/features/mobile">
              Features
            </FooterTopNavItem>
            <FooterTopNavItem to="/contact-us">Contact</FooterTopNavItem>
          </FooterTopNavContainer>
          <FooterTopNavContainer>
            <FooterTopNavHeader>Features</FooterTopNavHeader>
            <FooterTopItem>User App</FooterTopItem>
            <FooterTopItem>POS</FooterTopItem>
            <FooterTopItem>Consultancy</FooterTopItem>
            <FooterTopItem>Drug Interactions</FooterTopItem>
          </FooterTopNavContainer>
        </FooterTopNavs>
        <FooterTopNavContainer>
          <FooterTopNavHeader>Subscribe To Our Newsletter!</FooterTopNavHeader>
          <SubscribeSectionContainer>
            <input type="text" name="" id="" placeholder="Type Email" />
            <button>Subscribe</button>
          </SubscribeSectionContainer>
        </FooterTopNavContainer>
      </FooterTopContainer>
      <FooterBottom>
        <div>©2024. Zamda Health. All rights reserved</div>
        <FooterBottomRight>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms&conditions">Terms & Conditions</Link>
        </FooterBottomRight>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
