import "./App.css";
import Home from "./Pages/home/page";
import About from "./Pages/about/page";
import ContactUs from "./Pages/contact-us/page";
import Demo from "./Pages/demo/page";
import Privacy from "./Pages/privacy/page";
import TermsAndConditions from "./Pages/terms&conditions/page";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import RequestDemo from "./Pages/demo/RequestDemo";
import AwaitingList from "./Pages/demo/AwaitingList";
import { lightTheme } from "./theme";
import Header from "./components/Navs/Header";
import Footer from "./components/Navs/Footer";
import Mobile from "./Pages/features/mobile/page";
import Desktop from "./Pages/features/desktop/page";

function App() {
  const [theme, setTheme] = useState("light");
  const [showAwaitingListModal, setShowAwaitingListModal] = useState(false);
  const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);
  // const themeToggler = () => {
  //   theme === "light" ? setTheme("dark") : setTheme("light");
  //   localStorage.setItem("currentTheme", theme === "light" ? "dark" : "light");
  // };

  useEffect(() => {
    const currentTheme = localStorage.getItem("currentTheme");
    setTheme(currentTheme || "light");
  }, [theme]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <Header
          setShowAwaitingListModal={setShowAwaitingListModal}
          setShowRequestDemoModal={setShowRequestDemoModal}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/features/mobile" element={<Mobile />} />
          <Route path="/features/desktop" element={<Desktop />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
        <Footer />
        {showAwaitingListModal && (
          <AwaitingList closeModal={() => setShowAwaitingListModal(false)} />
        )}
        {showRequestDemoModal && (
          <RequestDemo closeModal={() => setShowRequestDemoModal(false)} />
        )}
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
