import React from "react";
import styled from "styled-components";
import { device } from "../../../theme";

const Button = styled.button`
  min-width: 150px;
  max-width: 300px;
  height: 49px;
  padding: 10px;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.grey};
  opacity: 0.9;
  box-shadow: ${({ theme }) => theme.boxShadow4};
  font-family: Inter, sans-serif; /* Added fallback font-family */
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  text-align: center;
  color: ${({ theme }) => theme.grey};
  background-color: ${({ theme }) => theme.white};
  outline: none;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }

  @media ${device.laptopL} {
    font-size: 22px;
    line-height: 26px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 24px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.mobileL} {
    font-size: 16px;
    line-height: 20px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
    line-height: 18px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
    line-height: 16px;
  }
`;


const RoundedOutlinedButton = ({ btnText, handleClick }) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick();
      }}
    >
      {btnText}
    </Button>
  );
};

export default RoundedOutlinedButton;
