import styled from "styled-components";
import { device } from "../../theme";

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media ${device.tablet} {
    margin-bottom: 80px;
  }

  @media ${device.mobileL} {
    margin-bottom: 60px;
  }

  @media ${device.mobileM} {
    margin-bottom: 40px;
  }

  @media ${device.mobileS} {
    margin-bottom: 20px;
  }
`;

export const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: flex-start;

  .header {
    margin-bottom: 50px;
  }

  @media ${device.tablet} {
    gap: 15px;
     text-align: center;

    .header {
      margin-bottom: 40px;
    }
  }

  @media ${device.mobileL} {
    gap: 10px;

    .header {
      margin-bottom: 30px;
    }
  }

  @media ${device.mobileM} {
    gap: 5px;

    .header {
      margin-bottom: 20px;
    }
  }

  @media ${device.mobileS} {
    gap: 5px;

    .header {
      margin-bottom: 10px;
    }
  }
`;

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    gap: 15px;
    text-align: center;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 5px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

export const FloatSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 100px 50px;

  @media ${device.laptopL} {
    padding: 0 80px 40px;
  }

  @media ${device.laptop} {
    padding: 0 60px 30px;
  }

  @media ${device.tablet} {
    padding: 0 40px 20px;
    align-items: center;
    text-align: center;
  }

  @media ${device.mobileL} {
    padding: 0 20px 10px;
  }

  @media ${device.mobileM} {
    padding: 0 10px 5px;
  }

  @media ${device.mobileS} {
    padding: 0 5px 5px;
  }
`;

export const AbsoluteDots = styled.img`
  position: absolute;
  top: 10px;
  left: 100px;

  @media ${device.tablet} {
    top: 8px;
    left: 80px;
    display: none;
  }

  @media ${device.mobileL} {
    top: 6px;
    left: 60px;
  }

  @media ${device.mobileM} {
    top: 4px;
    left: 40px;
  }

  @media ${device.mobileS} {
    top: 2px;
    left: 20px;
  }
`;
