import { createGlobalStyle } from "styled-components";
import { device } from "./theme";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        max-width: 100vw;
        min-height: 100vh;
        scroll-behavior: smooth;
        overflow-x: hidden;
    }

    body {
        max-width: 100vw;
        position: relative;
        min-height: 100vh;
        font-family: Arial, sans-serif; /* Add a default font-family */
        overflow-x: hidden;
    }

    .title-700 {
        font-size: 48px;
        font-weight: 700;
        color: ${({ theme }) => theme.purple};

        @media ${device.tablet} {
            font-size: 38px;
        }

        @media ${device.mobileL} {
            font-size: 28px;
        }

        @media ${device.mobileM} {
            font-size: 24px;
        }

        @media ${device.mobileS} {
            font-size: 18px;
        }
    }

    .sub-title-700 {
        font-size: 30px;
        font-weight: 700;
        line-height: 35.22px;
        color: ${({ theme }) => theme.purple};

        @media ${device.tablet} {
            font-size: 28px;
        }

        @media ${device.mobileL} {
            font-size: 26px;
        }

        @media ${device.mobileM} {
            font-size: 24px;
        }

        @media ${device.mobileS} {
            font-size: 22px;
        }
    }

    .regular-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        color: ${({ theme }) => theme.textGrey};

        @media ${device.tablet} {
            font-size: 18px;
        }

        @media ${device.mobileL} {
            font-size: 16px;
        }

        @media ${device.mobileM} {
            font-size: 14px;
        }

        @media ${device.mobileS} {
            font-size: 12px;
        }
    }

    .section-title {
        font-size: 44px;
        font-weight: 600;
        line-height: 57.2px;
        color: ${({ theme }) => theme.black3};

        @media ${device.tablet} {
            font-size: 40px;
        }

        @media ${device.mobileL} {
            font-size: 36px;
        }

        @media ${device.mobileM} {
            font-size: 32px;
        }

        @media ${device.mobileS} {
            font-size: 28px;
        }
    }

    .small-text-400 {
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        color: ${({ theme }) => theme.grey2};

        @media ${device.tablet} {
            font-size: 18px;
        }

        @media ${device.mobileL} {
            font-size: 16px;
        }

        @media ${device.mobileM} {
            font-size: 14px;
        }

        @media ${device.mobileS} {
            font-size: 12px;
        }
    }

    .title-600 {
        font-size: 45px;
        font-weight: 600;
        line-height: 52.83px;
        color: ${({ theme }) => theme.black3};

        @media ${device.tablet} {
            font-size: 42px;
        }

        @media ${device.mobileL} {
            font-size: 38px;
        }

        @media ${device.mobileM} {
            font-size: 34px;
        }

        @media ${device.mobileS} {
            font-size: 30px;
        }
    }

    .card-text {
        font-size: 24px;
        font-weight: 300;
        line-height: 29.35px;
        color: ${({ theme }) => theme.black};

        @media ${device.tablet} {
            font-size: 22px;
        }

        @media ${device.mobileL} {
            font-size: 20px;
        }

        @media ${device.mobileM} {
            font-size: 18px;
        }

        @media ${device.mobileS} {
            font-size: 16px;
        }
    }

    /* Displays */
    .flex-row {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        @media ${device.tablet} {
            gap: 8px;
            flex-direction: column;
        }

        @media ${device.mobileL} {
            gap: 6px;   
        }

        @media ${device.mobileM} {
            gap: 4px;
        }

        @media ${device.mobileS} {
            gap: 2px;
        }
    }

    img:hover {
        transform: scale(1.05);
        transition: all 0.5s ease-in-out;
    }
`;
