"use client";
import React from "react";
import { HomeContainer, LeftContentContainer } from "./style";
import ContactUs from "../contact-us/page";
import { DesktopAppCardList, mobileAppCardList } from "../home/utils";
import AnyQuestion from "../home/AnyQuestion";
import ImageTextSection from "../../components/Containers/ImageTextSection";
import CardListSection from "../../components/Containers/CardListSection";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";

const Demo = () => {
  return (
    <HomeContainer>
      <ImageTextSection
        leftContent={
          <>
            <LeftContentContainer>
              <p className="title-700">
                Request for Zamdahealth Point of Sale Software Demo for your
                Store.
              </p>
            </LeftContentContainer>
            <RoundedFilledButton
              btnText={"Request Demo"}
              borderRadius={"50px"}
            />
          </>
        }
        rightContent={
          <img
            alt=""
            src="/assets/images/svg/about-us/tablet-phone.svg"
            width={0}
            height={0}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
          />
        }
      />
      <ImageTextSection
        leftContent={
          <>
            <LeftContentContainer>
              <p className="sub-title-700">Zamda Health</p>
              <p className="regular-text">
                It is a technology-driven healthcare management company
                specializing in connecting pharmacies, customers, and healthcare
                professionals through a comprehensive online platform.
              </p>
            </LeftContentContainer>
            <LeftContentContainer>
              <p className="sub-title-700">Zamda Health Mobile App</p>
              <p className="regular-text">
                ZamdaHealth Mobile App is an innovative health tech Application
                which its core purpose is to promote access to quality medicine
                by making the process of ordering prescription and
                over-the-counter medications from a network of pharmacies
                easier. The company leverages artificial intelligence to
                streamline the entire healthcare process
              </p>
            </LeftContentContainer>
            <LeftContentContainer>
              <p className="sub-title-700">Zamda Health Point of Sale</p>
              <p className="regular-text">
                ZamdaHealth's advanced Point of Sale (POS) system for
                pharmacies, a comprehensive and versatile solution designed to
                streamline sales and inventory management. This powerful tool
                offers efficient transaction processing, automatic stock
                updates, and comprehensive sales analytics. It includes robust
                inventory management to keep track of stock levels and orders
                efficiently, and user management capabilities to handle customer
                profiles and preferences seamlessly.
              </p>
            </LeftContentContainer>
          </>
        }
        rightContent={
          <img
            alt=""
            src="/assets/images/svg/about-us/dashboard.svg"
            style={{
              cursor: "pointer",
              width: "90%",
              height: "100%",
            }}
            width={0}
            height={0}
          />
        }
      />

      <CardListSection
        sectionTitle={"Main Mobile Application Features"}
        cards={mobileAppCardList}
      />
      <CardListSection
        sectionTitle={"Main Desktop Point of Sales Features"}
        cards={DesktopAppCardList}
      />

      <ContactUs />

      <AnyQuestion />
    </HomeContainer>
  );
};

export default Demo;
