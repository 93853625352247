"use client";

import { useRef, useState } from "react";
import React from "react";
import { Container, FormSection, ImageSection } from "./style";
import emailjs from "@emailjs/browser";
import TextInput from "../../components/Form/Inputs/TextInput";
import TextArea from "../../components/Form/Inputs/TextArea";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";
const ContactUs = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    setLoading(true);
    emailjs
      .sendForm("service_dakbi0p", "template_il5pmem", form.current, {
        publicKey: "mygPawbiDLK6-Jnfb",
      })
      .then(
        () => {
          alert("Message sent!");
          setLoading(false);
        },
        (error) => {
          alert("FAILED...", error.text);
          setLoading(false);
        }
      );
  };

  return (
    <Container>
      <FormSection ref={form}>
        <div className="section-title">Get In Touch</div>
        <div className="flex-row">
          <TextInput
            label="Your name"
            placeholder={"John Doe"}
            required={true}
            name="name"
          />
          <TextInput
            label="Contact email"
            placeholder={"example@gmail.com"}
            required={true}
            name="email"
          />
        </div>
        <div className="flex-row">
          <TextInput
            label="Company name"
            placeholder={"Company name"}
            required={true}
            name="company"
          />
          <TextInput
            label="Country"
            placeholder={"Country"}
            required={true}
            name="country"
          />
        </div>
        <div className="flex-row">
          <TextArea
            label="Your Message"
            placeholder={"Type your message..."}
            required={true}
            name="message"
          />
        </div>
        <p className=".small-text-400">
          By submitting this form you agree to our terms and conditions and our
          Privacy Policy which explains how we may collect, use and disclose
          your personal information including to third parties.
        </p>
        <RoundedFilledButton
          btnText={"Submit"}
          borderRadius="10px"
          type={"submit"}
          loading={loading}
          handleClick={sendEmail}
        />
      </FormSection>
      <ImageSection>
        <img
          alt=""
          src="/assets/images/svg/contact-page/adress-img.svg"
          width={0}
          height={0}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        />
      </ImageSection>
    </Container>
  );
};

export default ContactUs;
