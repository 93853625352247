import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  color: ${({ theme }) => theme.black4};
`;

const Input = styled.textarea`
  width: 100%;
  height: 134.09px;
  border: 1px solid ${({ theme }) => theme.grey3};
  outline: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 18.78px;
  text-align: left;
  padding: 15px 20px;
  color: #183b56;
  border-radius: 15px;
  resize: none;
  width: 100%;

  &::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #959ead;
  }
`;

const TextArea = ({
  label,
  placeholder,
  handleChange,
  value,
  defaultValue,
  required,
  name
}) => {
  return (
    <Container>
      <Label>{label} {required && <sup>*</sup>}</Label>
      <Input
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        required={required}
        name={name}
      />
    </Container>
  );
};

export default TextArea;
