import React from "react";
import styled from "styled-components";
import SmallWhiteCard from "../Layouts/SmallWhiteCard";
import RoundedFilledButton from "../Form/Buttons/RoundedFilledButton";
import { device } from "../../theme";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 50px;
  margin-bottom: 50px;

  .title {
    width: 50%;
    text-align: center;

    @media ${device.tablet} {
      width: 70%;
      font-size: 20px;
      line-height: 25px;
    }

    @media ${device.mobileL} {
      width: 80%;
    }

    @media ${device.mobileM} {
      width: 90%;
    }

    @media ${device.mobileS} {
      width: 100%;
    }
  }

  @media ${device.tablet} {
    padding: 40px;
    gap: 25px;
    margin-bottom: 40px;
  }

  @media ${device.mobileL} {
    padding: 30px;
    gap: 20px;
    margin-bottom: 30px;
  }

  @media ${device.mobileM} {
    padding: 20px;
    gap: 15px;
    margin-bottom: 20px;
  }

  @media ${device.mobileS} {
    padding: 10px;
    gap: 10px;
    margin-bottom: 10px;
  }
`;

const CardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;

  @media ${device.laptopL} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  @media ${device.mobileL} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  @media ${device.mobileM} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  @media ${device.mobileS} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
`;

const CardListSection = ({
  sectionTitle,
  cards,
  showViewMore,
  viewMoreLink,
}) => {
  const router = useNavigate();

  return (
    <Container>
      <div className="section-title title">{sectionTitle}</div>
      <CardListContainer>
        {cards?.map((card, index) => (
          <SmallWhiteCard
            key={index}
            hasTextBackground={index === 0 && true}
            text={card?.text}
            icon={card?.icon}
          />
        ))}
      </CardListContainer>
      {showViewMore && (
        <RoundedFilledButton
          btnText={"View More"}
          borderRadius={"50px"}
          handleClick={() => viewMoreLink && router(viewMoreLink)}
        />
      )}
    </Container>
  );
};

export default CardListSection;
