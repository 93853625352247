"use client";

import { useRef, useState } from "react";
import React from "react";
import emailjs from "@emailjs/browser";
import { AwaitingListContainer, AwaitingListModal } from "./style";
import { FormSection } from "../contact-us/style";
import TextInput from "../../components/Form/Inputs/TextInput";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";
import TextArea from "../../components/Form/Inputs/TextArea";
const AwaitingList = ({ closeModal }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    setLoading(true);
    emailjs
      .sendForm("service_dakbi0p", "template_il5pmem", form.current, {
        publicKey: "mygPawbiDLK6-Jnfb",
      })
      .then(
        () => {
          alert("Message sent!");
          setLoading(false);
          closeModal();
        },
        (error) => {
          alert("FAILED...", error.text);
          setLoading(false);
        }
      );
  };

  return (
    <AwaitingListModal>
      <AwaitingListContainer>
        <FormSection ref={form}>
          <div className="section-title">Join Waiting List</div>
          <TextInput
            label="Full Name"
            placeholder={"John Doe"}
            required={true}
            name="name"
            type={"text"}
          />
          <TextInput
            label="Contact email"
            placeholder={"example@gmail.com"}
            required={true}
            name="email"
            type={"text"}
          />
          <TextInput
            label="Phone Number"
            placeholder={"+234 704 437 7963"}
            required={true}
            name="phone_number"
            type={"tel"}
          />
          <TextInput
            label="Country"
            placeholder={"Country"}
            required={true}
            name="country"
            type={"text"}
          />
          <TextInput
            label="State"
            placeholder={"State"}
            required={true}
            name="state"
            type={"text"}
          />
          <div className="flex-row" style={{ display: "none" }}>
            <TextArea
              label="Your Message"
              placeholder={"Type your message..."}
              required={true}
              name="message"
              value="Am interested in joining the waiting list"
            />
          </div>
          <div className="flex-row" style={{ flexDirection: "row" }}>
            <RoundedFilledButton
              btnText={"Join"}
              borderRadius="10px"
              type={"submit"}
              loading={loading}
              handleClick={sendEmail}
            />
            <RoundedFilledButton
              btnText={"Close"}
              borderRadius="10px"
              handleClick={closeModal}
            />
          </div>
        </FormSection>
      </AwaitingListContainer>
    </AwaitingListModal>
  );
};

export default AwaitingList;
