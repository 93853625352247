"use client";
import React from "react";
import {
  AbsoluteDots,
  LeftContentContainer,
  MobileContainer,
  RightContentContainer,
} from "./style";
import CardListSection from "../../../components/Containers/CardListSection";
import ImageTextSection from "../../../components/Containers/ImageTextSection";
import RoundedFilledButton from "../../../components/Form/Buttons/RoundedFilledButton";
import { DesktopAppCardList, desktopAppFeatures } from "../../home/utils";

const Desktop = () => {
  return (
    <MobileContainer>
      <ImageTextSection
        rightContent={
          <RightContentContainer>
            <p className="title-700">Desktop Point of Sale for Pharmacies</p>
            <p className="regular-text">
              ZamdaHealth's advanced Point of Sale (POS) system for pharmacies,
              a comprehensive and versatile solution designed to streamline
              sales and inventory management.
            </p>
            <RoundedFilledButton btnText={"Learn More"} />
          </RightContentContainer>
        }
        leftContent={
          <LeftContentContainer>
            <img
              alt=""
              src="/assets/images/svg/desktop-pos/box-dashbaord.svg"
              className="banner"
              width={0}
              height={0}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
            />
          </LeftContentContainer>
        }
        customStyles={{ alignItems: "center" }}
      />
      <CardListSection
        sectionTitle={"Main Desktop Point of Sales Features"}
        cards={DesktopAppCardList}
      />
      <CardListSection
        // sectionTitle={"Main Mobile Application Features"}
        cards={desktopAppFeatures}
      />
      <ImageTextSection
        leftContent={
          <>
            <LeftContentContainer>
              <AbsoluteDots src="/assets/images/svg/reusables/cyan-dots.svg" />
            </LeftContentContainer>
            <img
              alt=""
              src="/assets/images/svg/desktop-pos/pharm.svg"
              className="banner"
              width={0}
              height={0}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
            />
          </>
        }
        rightContent={
          <RightContentContainer>
            <p className="title-600 title">
              Advanced Pharmacy Inventory and Sales System
            </p>
            <RoundedFilledButton btnText={"Learn More"} borderRadius={"10px"} />
          </RightContentContainer>
        }
        customBackgroundUrl={
          "/assets/images/svg/reusables/grey-top-curvy-bg.svg"
        }
        customStyles={{ alignItems: "center" }}
      />
    </MobileContainer>
  );
};

export default Desktop;
