import styled from "styled-components";
import { device } from "../../theme";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media ${device.tablet} {
    margin-top: 40px;
  }

  @media ${device.mobileL} {
    margin-top: 30px;
  }

  @media ${device.mobileM} {
    margin-top: 20px;
  }

  @media ${device.mobileS} {
    margin-top: 10px;
  }
`;

export const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;

  @media ${device.tablet} {
    gap: 15px;
    text-align: center;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 5px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    gap: 15px;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 5px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;

export const AppsLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  img {
    /* width: 80%; */

    @media ${device.tablet} {
      width: 100px;
    }
  }

  @media ${device.tablet} {
    gap: 15px;
  }

  @media ${device.mobileL} {
    gap: 10px;
  }

  @media ${device.mobileM} {
    gap: 5px;
  }

  @media ${device.mobileS} {
    gap: 5px;
  }
`;
