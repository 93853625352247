import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin: 50px 100px;
  background: ${({ theme }) => theme.translucentWhite};
  height: 327px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: ${({ theme }) => theme.boxShadow4};

  @media (max-width: 768px) {
    margin: 30px 50px;
  }

  @media (max-width: 480px) {
    margin: 20px 20px;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.black};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.grey};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const AnyQuestion = () => {
  const router = useNavigate();

  return (
    <Container>
      <Title>Any questions for US?</Title>
      <Text>
        Reach out to us to get instant responses for any pharm related
        questions.
      </Text>
      <RoundedFilledButton
        btnText={"Contact Us"}
        borderRadius={"10px"}
        handleClick={() => router("/contact-us")}
      />
    </Container>
  );
};

export default AnyQuestion;
