"use client";

import React from "react";
import {
  AboutContainer,
  AbsoluteDots,
  FloatSection,
  LeftContentContainer,
} from "./style";
import { AppsLogoContainer } from "../home/styles";
import { useNavigate } from "react-router-dom";
import ImageTextSection from "../../components/Containers/ImageTextSection";
import RoundedFilledButton from "../../components/Form/Buttons/RoundedFilledButton";

const About = () => {
  const router = useNavigate();

  return (
    <AboutContainer>
      <ImageTextSection
        leftContent={
          <LeftContentContainer>
            <p className="title-700 header">About Zamda Health</p>
            <RoundedFilledButton btnText="Zamda Health" borderRadius={"10px"} />
            <p className="regular-text">
              It is a technology-driven healthcare management company
              specializing in connecting pharmacies, customers, and healthcare
              professionals through a comprehensive online platform. The company
              leverages artificial intelligence to streamline the entire
              healthcare process. This includes facilitating easy and efficient
              medication ordering, ensuring accurate prescription verification,
              and providing seamless online consultations with healthcare
              professionals. The goal is to optimize patient care and improve
              the overall healthcare experience by making it more accessible,
              efficient, and reliable for all users.
            </p>
            <RoundedFilledButton
              btnText="Zamda Health Mobile App"
              borderRadius={"10px"}
            />
            <p className="regular-text">
              ZamdaHealth Mobile App is an innovative health tech Application
              which its core purpose is to promote access to quality medicine by
              making the process of ordering prescription and over-the-counter
              medications from a network of pharmacies easier. Service users and
              patients can opt for home delivery or in-store pickup, choose the
              most cost-effective options, and benefit from AI-driven health
              services including medical consultations, drug interaction
              checker, allergy detection, prescription refill and many more.
            </p>
          </LeftContentContainer>
        }
        rightContent={
          <>
            <img
              alt=""
              src="/assets/images/svg/about-us/dashboard.svg"
              width={0}
              height={0}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "100%",
              }}
            />
            <AppsLogoContainer>
              <img
                src="/assets/images/svg/reusables/apple-store.svg"
                style={{
                  cursor: "pointer",
                }}
                alt="apple-store-logo"
              />
              <img
                src="/assets/images/svg/reusables/google-play-store.svg"
                style={{
                  cursor: "pointer",
                }}
                alt="google-store-logo"
              />
            </AppsLogoContainer>
          </>
        }
      />
      <FloatSection>
        <RoundedFilledButton
          btnText="Zamda Health Point of Sale"
          borderRadius={"10px"}
        />
        <p className="regular-text">
          ZamdaHealth's advanced Point of Sale (POS) system for pharmacies, a
          comprehensive and versatile solution designed to streamline sales and
          inventory management. This powerful tool offers efficient transaction
          processing, automatic stock updates, and comprehensive sales
          analytics. It includes robust inventory management to keep track of
          stock levels and orders efficiently, and user management capabilities
          to handle customer profiles and preferences seamlessly. The employee
          management module allows you to add employees, assign specific
          permissions, and monitor their performance effectively. With the
          ability to manage multiple branches and sales points, ZamdaHealth
          ensures consistent operations across different locations. The software
          integrates a marketplace for easy access to a wide range of products
          and services and features a discount function to create and manage
          promotional offers effortlessly. Designed to integrate seamlessly with
          our health tech app, ZamdaHealth’s POS system provides a unified
          experience for in-store and online orders. It enhances customer
          convenience with options for in-store pickup or home delivery,
          simplifying the process of handling and verifying prescriptions
          through its prescription upload function. Automatic online and offline
          sales reconciliation ensures that all transactions are accurately
          recorded and reconciled, while detailed analytics provide valuable
          insights into sales trends and performance metrics. Additionally, the
          system maintains regulatory compliance, protects sensitive data with
          robust security measures, and features a user-friendly interface to
          maximize productivity and customer satisfaction. Comprehensive
          reporting tools offer a complete overview of your business operations,
          helping you make informed decisions and ensuring the highest standards
          of service in your pharmacy.
        </p>
      </FloatSection>
      <ImageTextSection
        leftContent={
          <LeftContentContainer>
            <p className="title-700">Download Zamdahealth Mobile Application</p>
            <AppsLogoContainer>
              <img
                src="/assets/images/svg/reusables/apple-store.svg"
                style={{
                  cursor: "pointer",
                }}
                alt="apple-store-logo"
              />
              <img
                src="/assets/images/svg/reusables/google-play-store.svg"
                style={{
                  cursor: "pointer",
                }}
                alt="google-store-logo"
              />
            </AppsLogoContainer>
          </LeftContentContainer>
        }
        rightContent={
          <img
            alt=""
            src="/assets/images/svg/modile-app-images/mobile-phone.svg"
            width={0}
            height={0}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
          />
        }
        customStyles={{ alignItems: "center" }}
      />
      <ImageTextSection
        leftContent={
          <>
            <LeftContentContainer>
              <p className="sub-title-700">
                Request for Zamdahealth Point of Sale Software Demo for your
                Store.
              </p>
            </LeftContentContainer>
            <RoundedFilledButton
              btnText={"Request Demo"}
              borderRadius={"50px"}
              handleClick={() => router("/demo")}
            />
            <AbsoluteDots src="/assets/images/svg/reusables/cyan-dots.svg" />
          </>
        }
        rightContent={
          <img
            alt=""
            src="/assets/images/svg/about-us/tablet-phone.svg"
            width={0}
            height={0}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
          />
        }
        customBackgroundUrl={
          "/assets/images/svg/reusables/grey-top-curvy-bg.svg"
        }
        customStyles={{ alignItems: "center" }}
      />
    </AboutContainer>
  );
};

export default About;
