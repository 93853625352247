import styled from "styled-components";
import { device } from "../../../theme";

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media ${device.tablet} {
    margin-bottom: 80px;
  }

  @media ${device.mobileL} {
    margin-bottom: 60px;
  }

  @media ${device.mobileM} {
    margin-bottom: 40px;
  }

  @media ${device.mobileS} {
    margin-bottom: 20px;
  }
`;

export const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: flex-start;

  img.banner {
    width: 80%;

    @media ${device.tablet} {
      width: 70%;
    }

    @media ${device.mobileL} {
      width: 60%;
    }

    @media ${device.mobileM} {
      width: 50%;
    }

    @media ${device.mobileS} {
      width: 40%;
    }
  }

  @media ${device.tablet} {
    gap: 15px;
     text-align: center;
  }

  @media ${device.mobileL} {
    gap: 10px;
     text-align: center;
  }

  @media ${device.mobileM} {
    gap: 5px;
     text-align: center;
  }

  @media ${device.mobileS} {
    gap: 5px;
     text-align: center;
  }
`;

export const RightContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    gap: 15px;
    text-align: center;

    .title{
      font-size: 20px;
    }
  }

  @media ${device.mobileL} {
    gap: 10px;
     text-align: center;
  }

  @media ${device.mobileM} {
    gap: 5px;
     text-align: center;
  }

  @media ${device.mobileS} {
    gap: 5px;
     text-align: center;
  }
`;

export const FloatSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 100px 50px;

  @media ${device.laptopL} {
    padding: 0 80px 40px;
  }

  @media ${device.laptop} {
    padding: 0 60px 30px;
  }

  @media ${device.tablet} {
    padding: 0 40px 20px;
  }

  @media ${device.mobileL} {
    padding: 0 20px 10px;
  }

  @media ${device.mobileM} {
    padding: 0 10px 5px;
  }

  @media ${device.mobileS} {
    padding: 0 5px 5px;
  }
`;

export const AbsoluteDots = styled.img`
  position: absolute;
  top: 10px;
  left: 100px;

  @media ${device.tablet} {
    top: 8px;
    left: 80px;
    display: none;
  }

  @media ${device.mobileL} {
    top: 6px;
    left: 60px;
  }

  @media ${device.mobileM} {
    top: 4px;
    left: 40px;
  }

  @media ${device.mobileS} {
    top: 2px;
    left: 20px;
  }
`;

export const FeatureContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  span {
    font-size: 23px;
    font-weight: 500;
    line-height: 34.5px;
    color: ${({ theme }) => theme.grey};
  }

  span.selected {
    font-size: 23px;
    font-weight: 500;
    line-height: 27.84px;
    color: ${({ theme }) => theme.black3};
  }

  img {
    width: 20px;
  }

  @media ${device.tablet} {
    gap: 15px;

    span,
    span.selected {
      font-size: 20px;
      line-height: 30px;
    }

    img {
      width: 18px;
    }
  }

  @media ${device.mobileL} {
    gap: 10px;

    span,
    span.selected {
      font-size: 18px;
      line-height: 27px;
    }

    img {
      width: 16px;
    }
  }

  @media ${device.mobileM} {
    gap: 5px;

    span,
    span.selected {
      font-size: 16px;
      line-height: 24px;
    }

    img {
      width: 14px;
    }
  }

  @media ${device.mobileS} {
    gap: 5px;

    span,
    span.selected {
      font-size: 14px;
      line-height: 21px;
    }

    img {
      width: 12px;
    }
  }
`;
