import React, { useState } from "react";
import styled from "styled-components";
import RoundedFilledButton from "../Form/Buttons/RoundedFilledButton";
import RoundedOutlinedButton from "../Form/Buttons/RoundedOutlinedButton";
import { device } from "../../theme";
import { Link, useLocation, useNavigate } from "react-router-dom";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.blue};
  padding: 20px 50px;
  background: ${({ theme }) => theme.white};
  position: sticky;
  top: 0;
  z-index: 100;

  @media ${device.mobileL} {
    padding: 15px 20px;
  }

  @media ${device.tablet} {
    padding: 15px 30px;
  }
`;

const Logo = styled.img`
  width: 205px;
  height: 45px;
  cursor: pointer;

  @media ${device.mobileL} {
    width: 150px;
    height: 35px;
  }
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;

  @media ${device.tablet} {
    display: none; /* Hide desktop nav on tablet and below */
  }
`;

const NavItem = styled(Link)`
  padding: 10px 3px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.05px;
  color: ${({ theme }) => theme.grey};
  text-decoration: none;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &.active {
    padding-bottom: 5px;
    border-bottom: 2px solid ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.blue};
  }

  &:hover {
    color: ${({ theme }) => theme.blue};
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const NavParent = styled.div`
  padding: 10px 3px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.grey};
  cursor: pointer;
  position: relative;

  .active {
    padding-bottom: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.blue};
  }

  &:hover {
    color: ${({ theme }) => theme.blue};
  }

  @media ${device.mobileL} {
    font-size: 16px;
  }

  &:hover div {
    display: flex;
  }
`;

const NavChildren = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: auto;
  position: absolute;
  top: 100%;
  background: rgba(255, 255, 255, 0.9); /* Glassmorphism effect */
  backdrop-filter: blur(10px);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: none;
  border-radius: 10px;

  a {
    font-size: 16px;
    color: ${({ theme }) => theme.black2};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  @media ${device.tablet} {
    display: none; /* Hide buttons on tablet and below */
  }
`;

const MobileNavContainer = styled.div`
  display: none; /* Hidden by default */
  transition: all 0.5s ease-in-out;
  left: -100%;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    background: rgba(255, 255, 255, 0.9); /* Glassmorphism effect */
    backdrop-filter: blur(10px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 50%;
    left: ${(props) => (props.showMobileNav ? "0" : "-100%")};
    padding: 20px;
    border-radius: 10px;
    gap: 10px;
  }
`;

const MobileNavItem = styled(Link)`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.grey};
  text-decoration: none;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.active {
    padding-bottom: 5px;
    border-bottom: 2px solid ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.blue};
  }
`;

const MobileNavButton = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    font-size: 24px;
    color: ${({ theme }) => theme.grey};
  }
`;

const Header = ({ setShowAwaitingListModal, setShowRequestDemoModal }) => {
  const router = useNavigate();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { pathname } = useLocation();

  return (
    <HeaderContainer>
      <Logo alt="header-logo" src="/assets/images/zamda-logo.svg" onClick={() => router("/")} />
      <ButtonContainer>
        <NavContainer>
          <NavItem to="/" className={pathname === "/" && "active"}>
            Home
          </NavItem>
          <NavItem to="/about" className={pathname === "/about" && "active"}>
            About
          </NavItem>
          <NavParent>
            <div
              className={
                pathname === ("/features/mobile" || "features/desktop") &&
                "active"
              }
            >
              Features
            </div>
            <NavChildren>
              <NavItem to="/features/mobile">Mobile</NavItem>
              <NavItem to="/features/desktop">Desktop</NavItem>
            </NavChildren>
          </NavParent>
          <NavItem
            to="/contact-us"
            className={pathname === "/contact-us" && "active"}
          >
            Contact
          </NavItem>
        </NavContainer>
        <RoundedFilledButton
          btnText={"Demo"}
          handleClick={() => setShowRequestDemoModal(true)}
        />
        <RoundedOutlinedButton
          btnText={"Waiting List"}
          handleClick={() => setShowAwaitingListModal(true)}
        />
      </ButtonContainer>
      <MobileNavButton onClick={() => setShowMobileNav(!showMobileNav)}>
        ☰
      </MobileNavButton>
      {showMobileNav && (
        <MobileNavContainer showMobileNav={showMobileNav}>
          <MobileNavItem
            to="/"
            onClick={() => setShowMobileNav(false)}
            className={pathname === "/" && "active"}
          >
            Home
          </MobileNavItem>
          <MobileNavItem
            to="/about"
            onClick={() => setShowMobileNav(false)}
            className={pathname === "/about" && "active"}
          >
            About
          </MobileNavItem>
          <MobileNavItem
            to="/features/mobile"
            onClick={() => setShowMobileNav(false)}
            className={pathname === "/features/mobile" && "active"}
          >
            Mobile
          </MobileNavItem>
          <MobileNavItem
            to="/features/desktop"
            onClick={() => setShowMobileNav(false)}
            className={pathname === "/features/desktop" && "active"}
          >
            Desktop
          </MobileNavItem>
          <MobileNavItem
            to="/contact-us"
            onClick={() => setShowMobileNav(false)}
            className={pathname === "/contact-us" && "active"}
          >
            Contact
          </MobileNavItem>
          <RoundedFilledButton
            btnText={"Demo"}
            handleClick={() => setShowRequestDemoModal(true)}
          />
          <RoundedOutlinedButton
            btnText={"Waiting List"}
            handleClick={() => setShowAwaitingListModal(true)}
          />
        </MobileNavContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
