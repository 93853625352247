
export const mobileAppCardList = [
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/upload-prescription.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Upload Prescriptions",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/view-pham.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "View pharmacies closest to You",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/view-medications.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "View medications and prices",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/book-consultants.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Book for consultations",
  },
];

export const DesktopAppCardList = [
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/sales-system-pic.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Sales system Inventory management system",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/employee-management-pic.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Employee management System",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/sales-analytics.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Sales Analytics",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/prescription-upload.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Prescription upload",
  },
];

export const mobileAppFeatures = [
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/medical-informations.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Medical Information, ordering, Delivery Status Monitor",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/automatic-sales.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Drug interaction detection",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/refill-subscription.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Refill subscription",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/modile-app-images/up-to-date-health.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Up-to-date health and medication news and tips",
  },
];
export const desktopAppFeatures = [
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/multiple-sales.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Multiple sales point management",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/automatic-sales.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Automatic online and offline sales reconciliation",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/multiple-branch.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Multiple branch management",
  },
  {
    icon: (
      <img
        alt=""
        src="/assets/images/svg/desktop-pos/pham-marketplace.svg"
        style={{ width: "80%", height: "80%" }}
      />
    ),
    text: "Pharmacies Marketplace",
  },
];
